<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <v-dialog v-model="update_point_dialog" persistent max-width="600px">
                                    
                                    <v-card>
                                        <v-card-title>
                                            <span class="headline">Cập nhật POINT cho người dùng: #{{ this.selected_user ? this.selected_user.Id : "" }}</span>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <v-col cols="12" sm="12" md="12">
                                                        <v-text-field v-model="point_value" type="number" label="Số point (*)" required></v-text-field>
                                                    </v-col>
                                                    
                                                </v-row>
                                            </v-container>
                                            <small>(*) Trường bắt buộc</small>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="blue darken-1" text @click="update_point_dialog = false">Huỷ</v-btn>
                                            <v-btn color="blue darken-1" text @click="update_point()">Cập Nhật</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>

                
                
                




                <div class="row">
                    <div class="col-md-12">
                        <v-data-table
                        :headers="headers"
                        :items="users"
                        :loading="loading"
                        class="elevation-1"

                        @pagination="update_page"
                        :server-items-length="total_users"
                        :items-per-page="items_per_page"
                        :page="page"
                        >
                            <template v-slot:top>
                                <div class="d-flex align-items-center justify-content-between">
                                    <div class="col-md-4">
                                        <v-text-field
                                        append-icon="mdi-magnify"
                                        single-line
                                        hide-details
                                        v-model="query"
                                        label="Search"
                                        class="mx-4"
                                        ></v-text-field>
                                    </div>
                                </div>
                            </template>
                            
                            <template v-slot:item.ChichbongPoint="{ item }">
                                {{ item.ChichbongPoint ? item.ChichbongPoint : "0" }}
                            </template>
                            <template v-slot:item.update_point="{ item }">
                                <v-btn color="info" small @click="show_update_point(item)">
                                    Cập Nhật Point
                                </v-btn>
                            </template>

                        </v-data-table>
                    </div>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}

td {
    white-space:pre;
}

.coupon-area {
    padding: 12px;
}
.v-list-item:nth-of-type(even) {
    background-color: rgba(0, 0, 0, .05);
}
.v-list-item__title {
    font-weight: 600 !important;
}
.v-list-item__action--stack {
    display: flex;
    flex-direction: row !important;
    align-items: center !important;
    gap: 10px;
    margin: auto !important;
}
.v-dialog > .v-card > .v-card__subtitle {
    padding: 5px 24px 20px;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";

export default {
    metaInfo: {
        title: 'Tài khoản Zalo'
    },
    data() {
        return {
            loading: false,
            options: {},
            query: this.$route.query.query || "",
            dateRange: {},
            start: this.$route.query.start || "",
            end: this.$route.query.end || "",
            headers: [
                { text: "ID", align: 'start', sortable: false, value: 'Id'},
                { text: "Chichbong ID", value: 'ChichbongId', sortable: false },
                { text: "Tên", value: 'Name', sortable: false },
                { text: "SĐT", value: 'PhoneNum', sortable: false },
                { text: "Địa chỉ", value: 'Address', sortable: false },
                { text: "Point", value: 'ChichbongPoint', sortable: false },
                { text: "", value: 'update_point', sortable: false },
            ],

            // for modal
            update_point_dialog: false,
            selected_user: null,
            point_value: 0,

        };
    },
    watch: {
        options: {
            handler () {
                this.get_users();
            },
            deep: true,
        },
        query: {
            handler() {
                this.search_users();
            },
            deep: true
        },
    },
    computed: {
        users() {
            return this.$store.getters['users_zalo/get_users'];
        },
        total_users() {
            return this.$store.getters['users_zalo/get_total_users'];
        },
        page() {
            return this.$route.query.page ? parseInt(this.$route.query.page) : 1;
        },
        items_per_page() {
            return this.$route.query.limit ? parseInt(this.$route.query.limit) : 15;
        },
    },
    components: {
    },
    methods: {
        get_users() {
            var self    = this;
            self.loading = true;
            const page  = this.$route.query.page || 1;
            const limit = this.$route.query.limit || 15;
            const q = this.query || "";
            const start = this.start || "";
            const end = this.end || "";

            var payload = {
                pageSize: limit,
                pageIndex: page,
            };

            if(q) {
                payload.nameQuery = q;
            }
            if(start && end) {
                payload.startTimeRegisted = start;
                payload.endTimeRegisted = end;
            }

            this.$router.push({
                name: "users_zalo-index",
                query: { page, limit, q, start, end }
            }).catch(() => { });

            this.$store.dispatch('users_zalo/get_all', payload).then(() => {
                self.loading = false;
            }).catch(() => {
                self.loading = false;
            });
        },
        search_users() {
            const page  = 1;
            const limit = this.$route.query.limit || 15;
            const start = this.start;
            const end = this.end;

            this.$router.push({
                name: "users_zalo-index",
                query: { page, limit, q: this.query, start, end }
            }).catch(() => { });
            this.get_users();
        },
        edit_item(item) {
            console.log("edit item:", item);
            // this.$router.push(`categories/update/${item.id}`).catch(() => { });
        },
        update_page(pagination) {
            const page  = pagination.page;
            const limit = pagination.itemsPerPage;
            const q = this.query;
            const start = this.start;
            const end = this.end;

            this.$router.push({
                name: "users_zalo-index",
                query: { page, limit, q, start, end }
            }).catch(() => { });
            this.get_users();
        },
        get_pin(id) {
            this.$store.dispatch('users/get_pin', {id}).then(res => {
                if(res.data.Data) {
                    Swal.fire({
                        text: `PIN: ${res.data.Data.Pincode}`,
                        icon: "success",
                        heightAuto: false
                    });
                } else {
                    Swal.fire({
                        text: `Có lỗi xảy ra`,
                        icon: "error",
                        heightAuto: false
                    });
                }
                
            }).catch(e => {
                console.log(e);
                Swal.fire({
                    text: `Có lỗi xảy ra`,
                    icon: "error",
                    heightAuto: false
                });
            });
        },
        format_date(date) {
            return new Date(date).toLocaleString('vi-VN', {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
        },
        show_update_point(item) {
            this.selected_user = item;
            this.update_point_dialog = true;
            this.point_value = item.ChichbongPoint || 0;
        },
        update_point() {
            this.loading = true;
            var self = this;

            let id = this.selected_user ? this.selected_user.Id : null;
            let point = this.point_value || 0;

            console.log("Cap nhat cho: ", id, point);

            this.$store.dispatch('users_zalo/update_point', {id, point, toast: this.$root.$bvToast}).then(() => {
                self.get_users();
                self.update_point_dialog = false;
                self.loading = false;
            }).catch(() => {
                self.get_users();
                self.update_point_dialog = false;
                self.loading = false;
            });;
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [ { title: "Tài khoản", route: "index" }, { title: "Tất cả" } ]);
        this.get_users();
    }
};
</script>
